import {TemplateInstance, propertyIdentityOrBooleanAttribute} from '@github/template-parts'
import {controller, target} from '@github/catalyst'

import VirtualFilterInputElement from '../../assets/modules/github/virtual-filter-input-element'
import VirtualListElement from '../../assets/modules/github/virtual-list-element'

type ProjectSuggestion = {
  id: number
  name: string
  owner: string
  type: string
  selected: boolean
}

type SelectionDiff = {
  [project_type: string]: {[id: number]: boolean}
}

/**
 * Project picker element used in the sidebar.
 * Controls the virtual list and filter input.
 *
 * Key responsibilities:
 * - Rendering: rendering list items, showing loading and empty states
 * - Tracking selection: tracks diff of selected & deselected items, serializing the state for form submission
 * - Syncing list visibility with virtual rendering lifecycle
 */
@controller
class ProjectPickerElement extends HTMLElement {
  @target input: VirtualFilterInputElement<ProjectSuggestion>
  @target list: VirtualListElement<ProjectSuggestion>
  @target itemTemplate: HTMLTemplateElement
  @target selectionTemplate: HTMLTemplateElement
  @target submitContainer: HTMLElement
  @target blankslate: HTMLElement
  @target loadingIndicator: SVGElement

  loading = true

  selectionDiff: SelectionDiff = {
    memex_project: {},
    project: {}
  }

  connectedCallback() {
    this.loadChanges()
    this.input.filter = (item: ProjectSuggestion, query: string) => {
      return item && item.name.toLowerCase().trim().includes(query.toLowerCase())
    }

    // workaround the 0 size of the virtual list in case it's hidden behind the details menu
    const detailsMenu = this.closest('details.select-menu') as HTMLDetailsElement
    if (detailsMenu && this.list) {
      detailsMenu.addEventListener('toggle', () => {
        if (detailsMenu.open === false) {
          this.input.clear()
          return
        }
        this.list.update()
      })
    }
  }

  willLoad() {
    this.loading = true
    this.loadingIndicator.removeAttribute('hidden')
    this.blankslate.hidden = true

    // Resetting input here instead on changeTab becasue setting loading = true will prevent displaying the blankslate
    this.input.reset()
  }

  didLoad() {
    this.loading = false
    this.loadingIndicator.setAttribute('hidden', 'true')
  }

  changeTab(event: CustomEvent) {
    const tabSource = event.detail.relatedTarget.getAttribute('data-src') || ''
    // changing the src for the virtual input will trigger a new request for data
    this.input.src = tabSource
    // changing loading to eager to make sure to new request is made
    this.input.loading = 'eager'
  }

  willRenderItems() {
    this.blankslate.hidden = this.list.size > 0 || this.loading
  }

  renderItem(event: CustomEvent) {
    const frag = new TemplateInstance(
      this.itemTemplate,
      {
        id: event.detail.item.id,
        project_icon_hidden: event.detail.item.type !== 'project',
        table_icon_hidden: event.detail.item.type !== 'memex_project',
        name: event.detail.item.name,
        owner: event.detail.item.owner,
        type: event.detail.item.type,
        selected: this.isProjectSelected(event.detail.item)
      },
      propertyIdentityOrBooleanAttribute
    )

    // append the element to virtual list fragment container
    // this is the way you define what to render
    event.detail.fragment.append(frag)
  }

  isProjectSelected(suggestion: ProjectSuggestion) {
    return this.selectionDiff[suggestion.type][suggestion.id] ?? suggestion.selected
  }

  toggleItem(event: MouseEvent) {
    // handle clicks originated only from a checkbox inside a label
    if (!(event.target instanceof HTMLInputElement) || event.target.type !== 'checkbox') return

    const projectElement = event.target.closest('[data-project-id]')
    if (!projectElement) {
      return
    }

    const projectId = parseInt(projectElement.getAttribute('data-project-id') || '', 10)
    const projectType = projectElement.getAttribute('data-project-type') || ''

    const selected = projectElement.getAttribute('aria-checked') || ''
    if (selected === 'true') {
      // Deselecting
      this.selectionDiff[projectType][projectId] = false
      projectElement.setAttribute('aria-checked', 'false')
    } else {
      // Selecting
      this.selectionDiff[projectType][projectId] = true
      projectElement.setAttribute('aria-checked', 'true')
    }
    this.serializeSelection()
  }

  createSubmitSelection(projectType: string, projectId: string, selected: boolean) {
    const name = `${
      projectType === 'memex_project' ? 'issue_memex_project_ids' : 'issue_project_ids'
    }[${projectId.toString()}]`

    const el = new TemplateInstance(
      this.selectionTemplate,
      {name, checked: selected, type: selected ? 'checkbox' : 'hidden'},
      propertyIdentityOrBooleanAttribute
    )

    return el
  }

  serializeSelection() {
    this.submitContainer.replaceChildren()

    for (const [projectId, selected] of Object.entries(this.selectionDiff.project)) {
      this.submitContainer.append(this.createSubmitSelection('project', projectId, selected))
    }

    for (const [projectId, selected] of Object.entries(this.selectionDiff.memex_project)) {
      this.submitContainer.append(this.createSubmitSelection('memex_project', projectId, selected))
    }
  }

  loadChanges() {
    // When creating a new issue, we need to preopulate selectionDiff with the values from the form.
    // Those values are present if new issue is created (issues#new).
    const regex = /(\S+)\[([0-9]+)\]/
    for (const input of Array.from(this.submitContainer.children)) {
      const name = input.getAttribute('name')
      if (name === null) continue

      const matches = name.match(regex)
      if (!matches) continue

      const inputFieldName = matches[1]
      const projectId = parseInt(matches[2], 10)

      switch (inputFieldName) {
        case 'issue_project_ids':
          this.selectionDiff['project'][projectId] = true
          break
        case 'issue_memex_project_ids':
          this.selectionDiff['memex_project'][projectId] = true
          break
        default:
          throw new Error(`Unknown checkbox name: ${input.getAttribute('name')}`)
      }
    }
  }
}
